import { useEffect, useState, useMemo } from 'react'
import { sizes } from 'assets/styles/media'
import { debounce } from '@purple/common/utils/eventsUtility'

const getStoreMediaType = () => {
  const width = window.innerWidth
  if (width <= sizes.small) {
    return 'small'
  } else if (width <= sizes.phone) {
    return 'phone'
  } else if (width <= sizes.tablet) {
    return 'tablet'
  } else if (width <= sizes.desktop) {
    return 'desktop'
  } else if (width <= sizes.giant) {
    return 'giant'
  } else if (width <= sizes.jumbo) {
    return 'jumbo'
  } else {
    return 'max'
  }
}

export default function useMediaType(targetMediaType) {
  const [mediaType, setMediaType] = useState(getStoreMediaType())

  // 전달된 targetMediaType 이하일 때 true
  const checkMediaType = useMemo(() => {
    return sizes[mediaType] <= sizes[targetMediaType]
  }, [targetMediaType, mediaType])

  useEffect(() => {
    const handleResize = debounce(() => {
      setMediaType(getStoreMediaType())
    }, 100)

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    mediaType,
    checkMediaType
  }
}
