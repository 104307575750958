import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import { moveBoard } from 'routes/board/locations'

import {
  Icons,
  ContainedButton,
  ActionButton,
  Thumbnail
} from '@purple/design/react'

// Constants
import { BOARD_TYPE } from 'constants/boardTypes'
import { MEDIA_TYPE } from 'constants/mediaTypes'

// Utils
import { getLocale } from '@purple/common/utils/commonUtility'
import { Config as config } from '@purple/common/utils/globalUtility'
import { isAfter24Hours, dateFormat } from '@purple/common/utils/dayjsUtility'
import {
  onError,
  profileThumbnailUrl,
  defaultNullImage
} from 'utils/imageUtility'
import {
  getBoardType,
  getCategoryName,
  commonBoardError
} from 'utils/boardUtilsForStore'
import { commonSessionError } from 'utils/appUtility'
import { ellipsis } from 'assets/styles/mixin'

// RTK
import {
  useDeleteArticleMutation,
  useGetArticleDetailQuery,
  useGetBoardQuery,
  useLazyGetMyBanQuery
} from 'store/services/query/boardQuery'

// Hooks
import useShare from 'hooks/common/useShare'
import useToast from 'hooks/common/useToast'
import useSearchParams from 'hooks/common/useSearchParams'
import { useGetMyProfileInfo } from 'hooks/common/usePurpleAsync'

// Style
import {
  BottomButtonWrap,
  ViewEditorSection
} from 'components/Board/Common/Core/BoardStyled'
import { media } from 'assets/styles/media'

// Components
import ViewExtra from 'components/Board/Common/Core/ViewExtra'
import PrevnextArticle from 'components/Board/Common/Core/PrevnextArticle'
import Comments from 'components/Board/Common/Core/Comments'
import { boardReportPopup } from 'components/Popup/index'
import { confirmPopup, mediaViewerPopup } from 'components/Popup'
import Popper, { PopperMoreContent } from 'components/Common/Popper'

const Container = styled.div`
  ${({ layoutType }) => (layoutType === 'popup' ? 'padding: 0 12px;' : '')}
`

const Header = styled.div`
  position: relative;
  padding: ${({ layoutType }) => (layoutType === 'popup' ? '32px' : '0')}
    ${({ isShowMenu }) => (isShowMenu ? '50px' : '0')} 32px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.bright200};
  border-top: ${({ theme, layoutType }) =>
    layoutType === 'popup' ? `1px solid ${theme.color.bright200} ` : 'none'};

  ${media.phone`
    padding: ${({ layoutType }) => (layoutType === 'popup' ? '24px' : '0')}
    ${({ isShowCategory, isShowMenu }) =>
      !isShowCategory && isShowMenu ? '50px' : '0'} 24px 0;
  `};
`

const Title = styled.div`
  padding-right: 16px;
  margin-bottom: 8px;
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.color.glyphs200};

  word-break: break-all;

  svg {
    margin-left: 3px;
    margin-bottom: 4px;
    vertical-align: middle;
  }

  i {
    margin-left: 4px;
    color: ${({ theme }) => theme.color.glyphs300};
  }

  ${media.phone`
    ${({ theme }) => theme.typography.subtitle1};
  `};
`

const Category = styled.em`
  ${({ theme }) => theme.typography.subtitle4};
  color: ${({ theme }) => theme.color.point9};
  margin-right: 8px;
  flex-shrink: 0;

  &:empty {
    display: none;
  }

  ${media.phone`
    display: inline-flex;
    align-items: center;
    ${({ theme }) => theme.typography.caption2};
    width: 100%;
    height: 32px;
    margin-bottom: 4px;

    &:empty {
      display: inline-flex;
      margin: 0;
    }
  `};
`

const Meta = styled.div`
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.color.glyphs300};
  white-space: nowrap;
  display: flex;

  & > span {
    display: flex;
    align-items: center;
    & > i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: ${({ theme }) => theme.color.button100};
      margin-left: 2px;
      border-radius: 50%;
      & > svg {
        fill: ${({ theme }) => theme.color.white900a};
      }
    }
  }

  & > span + span::before {
    content: '·';
    display: inline-block;
    margin: 0 4px;
  }

  ${media.phone`
    ${({ theme }) => theme.typography.body5};
  `};
`
const Profile = styled.div`
  display: flex;
  margin-right: 4px;
  border: 1px solid ${({ theme }) => theme.color.bright100};
  border-radius: 50%;
`
const Writer = styled.span`
  color: ${({ theme }) => theme.color.glyphs250};
  min-width: 0;
  & > span {
    ${ellipsis}
  }
  & > i {
    flex-shrink: 0;
  }
`

const MenuLayer = styled.ul`
  position: absolute;
  display: none;
  right: 0px;
  top: 32px;
  padding: 8px;
  min-width: 176px;
  border: 1px solid ${({ theme }) => theme.color.bright200};
  border-radius: 8px;
  ${({ theme }) => theme.typography.body3};
  ${({ theme }) => theme.elevation[12]};
  color: ${({ theme }) => theme.color.glyphs300};
  background: ${({ theme }) => theme.color.bg300};

  li {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 4px 8px;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.color.glyphs100};
      background: ${({ theme }) => theme.color.bright100};
    }

    & + li {
      margin-top: 2px;
    }
  }
`

const Menu = styled.div`
  position: absolute;
  right: 0px;
  top: 0;
  top: ${({ layoutType }) => (layoutType === 'popup' ? '28px' : '0')};

  &:hover ${MenuLayer} {
    display: block;
  }
`

const Read = styled.section`
  padding: 32px 0;
  color: ${({ theme }) => theme.color.glyphs200};
  ${({ theme }) => theme.typography.reading3};

  ${ViewEditorSection}
`

function BoardView(props) {
  const {
    gameCode = (() => {
      const { gameCode } = useParams()
      return gameCode
    })(),
    boardAlias = (() => {
      const { boardAlias } = useParams()
      return boardAlias
    })(),
    serviceAlias = (() => {
      const { serviceAlias } = useParams()
      return serviceAlias
    })(),
    articleId = (() => {
      const { articleId } = useParams()
      return articleId
    })(),
    boardType,
    layoutType = '',
    options = {}
  } = props
  const categoryAlias = useMemo(
    () => (gameCode ? `${boardAlias}_${gameCode}` : undefined),
    [boardAlias, gameCode]
  )

  const searchParams = useSearchParams()
  const query = useMemo(() => searchParams.get('query'), [searchParams])

  const option = useMemo(
    () => ({
      postDate: true, // 작성일
      category: true, // 카테고리
      writer: ![BOARD_TYPE.NEWS, BOARD_TYPE.LNEWS].includes(boardType), // 작성자 이름
      profile: ![BOARD_TYPE.NEWS, BOARD_TYPE.LNEWS].includes(boardType), // 작성자 프로필 사진
      newBadge: true, // 새글 뱃지
      hit: ![BOARD_TYPE.NEWS, BOARD_TYPE.LNEWS].includes(boardType), // 조회수
      share: true, // 공유하기
      report: true, // 신고하기
      writable: true, // 쓰기가능
      comment: true, // 코멘트 제공
      relativeArtice: true, // 이전글,다음글
      like: true, // 좋아요
      ...options
    }),
    [options, boardType]
  )

  const { npUserId, loginStatus } = useSelector(
    (state) => ({
      npUserId: state.userSlice.npUserId,
      loginStatus: state.userSlice.loginStatus
    }),
    shallowEqual
  )

  const history = useHistory()
  const location = useLocation()
  const { search } = useLocation()
  const { t } = useTranslation()
  const { onShare } = useShare()
  const { onInfoToast } = useToast()
  const innerHTMLRef = useRef(null)
  const clickHandlersRef = useRef([])
  const errorHandlersRef = useRef([])

  const [popper, setPopper] = useState({
    open: false,
    target: null,
    preventElements: []
  })

  // 상세 호출
  const { data, isError } = useGetArticleDetailQuery({
    boardAlias,
    articleId
  })
  const boardInfo = useGetBoardQuery({
    boardAlias
  })
  const [deleteArticleTrigger] = useDeleteArticleMutation()
  const [getMyBanTrigger] = useLazyGetMyBanQuery()
  const { getMyProfileInfo } = useGetMyProfileInfo()

  const isMe = useMemo(
    () => data?.content_meta?.writer?.login_user?.uid === npUserId,
    [data, npUserId]
  )
  const isAdmin = useMemo(
    () => !!data?.content_meta?.writer?.admin || false,
    [data]
  )
  const isShowMenu = useMemo(
    () => option.share || (!isMe && !isAdmin) || option.writable,
    [option, isMe, isAdmin]
  )

  // 핸들러 - 목록 이동
  const handleLinkList = useCallback(() => {
    history.push(
      {
        pathname: moveBoard(
          {
            gameCode,
            boardAlias
          },
          `${getBoardType({ boardAlias, boardType })}_LIST`
        ),
        search
      },
      location.state
    )
  }, [boardAlias, gameCode, history, boardType, location, search])

  // 핸들러 - 글쓰기 이동
  const handleLinkWrite = useCallback(async () => {
    if (!loginStatus) {
      commonSessionError()
      return
    }
    try {
      // 1. 제재 계정 체크
      const res = await getMyBanTrigger({ serviceAlias }).unwrap()

      if (res.banned === true) {
        // 제재 계정 안내
        confirmPopup({
          type: 'alert',
          msg: t('You cannot use the service with a sanctioned account')
        })
      } else {
        // 2. 퍼플프로필 여부 조회
        await getMyProfileInfo({
          successCallback: () => {
            history.push({
              pathname: moveBoard(
                {
                  gameCode,
                  boardAlias
                },
                `${getBoardType({ boardAlias, boardType })}_WRITE`
              ),
              search
            })
          }
        })
      }
    } catch (error) {
      console.error(error)
      commonBoardError(error).execute()
    }
  }, [
    history,
    gameCode,
    boardAlias,
    boardType,
    search,
    getMyBanTrigger,
    loginStatus,
    getMyProfileInfo,
    serviceAlias,
    t
  ])

  // 핸들러 - 더보기 버튼 클릭
  const handleMoreClick = useCallback(
    (e) => {
      const target = e.currentTarget
      if (!popper.open) {
        setPopper((prev) => ({
          ...prev,
          preventElements: [target],
          open: true,
          target
        }))
      } else {
        setPopper((prev) => ({
          open: false,
          target: null,
          preventElements: []
        }))
      }
    },
    [popper]
  )

  // 핸들러 - 게시글 공유하기
  const handleShare = useCallback(
    async (e) => {
      //소니게시판용 기본 URL만 설정했습니다. 공유하는 게시판 추가되면 라우트에서부터 보내줘야함
      let shareUrl = `${
        config.purpleDomain || 'https://purple.plaync.com'
      }/game/${gameCode}/board/${boardAlias}/view/${articleId}`
      const replaceVal = {
        gameCode,
        articleId
      }

      if (boardInfo?.data) {
        shareUrl =
          boardInfo.data?.board?.board_url_pattern?.replace(
            /\${(.*?)}/g,
            (x, k) => replaceVal[k]
          ) || shareUrl
      }

      onShare({
        url: shareUrl,
        option: { arr: ['url'] }
      })
    },
    [onShare, boardAlias, gameCode, articleId, boardInfo]
  )

  // 핸들러 - 게시글 신고하기
  const handleReport = useCallback(
    (name = '') => {
      boardReportPopup(
        { name, boardAlias, articleId },
        {
          allowLocationChangeNotClose: false,
          stringKey: 'BoardReport'
        }
      )
    },
    [boardAlias, articleId]
  )

  // 핸들러 - 게시글 삭제
  const handleDelete = useCallback(() => {
    confirmPopup({
      type: 'confirm',
      msg: t('are you sure you want to delete?'),
      onRight: async () => {
        await deleteArticleTrigger({
          boardAlias,
          articleId
        }).unwrap()

        onInfoToast({
          message: t('deleted'),
          position: 'bottom'
        })

        history.push(moveBoard({ gameCode, boardAlias }, `${boardType}_LIST`), {
          refetch: true
        })
      }
    })
  }, [
    t,
    deleteArticleTrigger,
    gameCode,
    boardType,
    boardAlias,
    articleId,
    history,
    onInfoToast
  ])

  // 핸들러 - 게시글 수정
  const handleModify = useCallback(() => {
    history.push(
      moveBoard({ gameCode, boardAlias, articleId }, `${boardType}_EDIT`)
    )
  }, [gameCode, boardAlias, articleId, boardType, history])

  // 핸들러 - 이미지 뷰어
  const handleImgViewer = useCallback((event, index, array) => {
    const mediaList = Array.from(array)?.map((item, idx) => ({
      originIndex: idx,
      id: idx,
      mediaType: MEDIA_TYPE.IMAGE,
      viewUrl: item.src,
      downloadUrl: item.src
    }))

    mediaViewerPopup(
      {
        mediaList,
        index: mediaList.findIndex((item) => item.originIndex === index),
        options: {
          download: false
        }
      },
      {
        isReplace: true
      }
    )
  }, [])

  // 이펙트 - 에러 시 목록 이동
  useEffect(() => {
    if (isError) {
      handleLinkList()
    }
  }, [isError, handleLinkList])

  // 이펙트 - 이미지 이벤트 바인딩
  useEffect(() => {
    if (innerHTMLRef.current) {
      const elements = innerHTMLRef.current.querySelectorAll('.fe-image')

      if (!!elements?.length) {
        elements.forEach((element, index, array) => {
          const imgClickEvent = (event) => handleImgViewer(event, index, array)
          const imgErrorEvent = (event) => onError(event, defaultNullImage)
          element.addEventListener('click', imgClickEvent)
          clickHandlersRef.current[index] = imgClickEvent
          element.addEventListener('error', imgErrorEvent)
          errorHandlersRef.current[index] = imgErrorEvent
        })
      }

      return () => {
        if (!!elements?.length) {
          elements.forEach((element, index) => {
            element.removeEventListener(
              'click',
              clickHandlersRef.current[index]
            )
            element.removeEventListener(
              'error',
              errorHandlersRef.current[index]
            )
          })
        }
      }
    }
  }, [data?.content?.content, handleImgViewer])

  if (!data) {
    return null
  }

  return (
    <Container layoutType={layoutType}>
      <Header
        isShowMenu={isShowMenu}
        isShowCategory={option.category}
        layoutType={layoutType}
      >
        <Title isShowMenu={isShowMenu}>
          {option.category && (
            <Category>
              {getCategoryName(data.content_meta?.category_board?.board_alias)}
            </Category>
          )}
          <span>{data.content_meta?.title}</span>
          {option.comment && data.content_meta?.reactions?.comment_count ? (
            <i>[{data.content_meta?.reactions?.comment_count || ''}]</i>
          ) : (
            ''
          )}
          {option.newBadge &&
            isAfter24Hours(data.content_meta?.timestamps?.posted_at) ===
              false && <Icons name="BadgeNew" width="16" height="16" />}
        </Title>
        <Meta>
          {option.profile && (
            <Profile>
              <Thumbnail
                alt=""
                gameCode=""
                size={100}
                imgBg="point7"
                sources=""
                src={
                  data?.content?.service_reserved?.reserved1
                    ?.store_purple_profile_image ||
                  profileThumbnailUrl(
                    data.content_meta?.writer?.login_user?.uid || ''
                  )
                }
                type="character"
                onError={onError}
              />
            </Profile>
          )}
          {option.writer && (
            <Writer>
              <span>
                {data?.content?.service_reserved?.reserved1
                  ?.store_purple_profile_name ||
                  data.content_meta?.writer?.login_user?.name}
              </span>
              {data?.content?.service_reserved?.reserved1
                ?.store_has_package && (
                <i title={t('users who purchased the game')}>
                  <Icons name="GameControllerFilled" width="16" height="16" />
                </i>
              )}
            </Writer>
          )}
          {option.postDate && (
            <span>{dateFormat(data.content_meta?.timestamps?.posted_at)}</span>
          )}
          {option.hit && (
            <span>
              {t('views')} {data.content_meta?.reactions?.view_count ?? 0}
            </span>
          )}
        </Meta>
        {isShowMenu && (
          <Menu layoutType={layoutType}>
            <div onClick={handleMoreClick}>
              <ActionButton type="basicSub" size="medium">
                <Icons name="MoreVert" width="20" height="20" />
              </ActionButton>
              <Popper
                open={popper.open}
                target={popper.target}
                preventElements={popper.preventElements}
                strategy={'absolute'}
                portal={false}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 4]
                    }
                  },
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: document.getElementById('content'),
                      padding: 8
                    }
                  },
                  {
                    name: 'observeReferenceModifier',
                    enabled: true,
                    phase: 'main',
                    effect: ({ state, instance }) => {
                      const RO_PROP = '__popperjsRO__'
                      const { reference } = state.elements

                      reference[RO_PROP] = new ResizeObserver((entries) => {
                        instance.update()
                      })
                      reference[RO_PROP].observe(reference)

                      return () => {
                        reference[RO_PROP].disconnect()
                        delete reference[RO_PROP]
                      }
                    }
                  }
                ]}
                placement={'bottom-end'}
                onClose={() => {
                  setPopper((prev) => ({
                    open: false,
                    target: null,
                    preventElements: []
                  }))
                }}
              >
                {() => (
                  <PopperMoreContent>
                    {option.share && (
                      <button onClick={handleShare}>{t('sharing')}</button>
                    )}
                    {!isMe && !isAdmin && (
                      <button
                        onClick={() =>
                          handleReport(
                            data?.content?.service_reserved?.reserved1
                              ?.store_purple_profile_name ||
                              data.content_meta?.writer?.login_user?.name
                          )
                        }
                      >
                        {t('report')}
                      </button>
                    )}
                    {option.writable && isMe && (
                      <>
                        <button onClick={handleModify}>{t('modify')}</button>
                        <button onClick={handleDelete}>{t('delete')}</button>
                      </>
                    )}
                  </PopperMoreContent>
                )}
              </Popper>
            </div>
          </Menu>
        )}
      </Header>

      <Read
        ref={innerHTMLRef}
        dangerouslySetInnerHTML={{ __html: data.content?.content }}
      />

      <BottomButtonWrap>
        <ContainedButton
          size="medium"
          type="outlineSecondary"
          onClick={handleLinkList}
          width="100px"
        >
          {t('list')}
        </ContainedButton>

        {option.writable && (
          <ContainedButton
            size="medium"
            type="tertiary"
            onClick={handleLinkWrite}
            width="100px"
          >
            {t('writing')}
          </ContainedButton>
        )}
      </BottomButtonWrap>

      {(option.like || option.comment) && (
        <ViewExtra
          likeCount={data?.content_meta?.reactions?.like_count || 0}
          commentCount={data?.content_meta?.reactions?.comment_count || 0}
          options={option}
        />
      )}

      {option.comment && (
        <Comments
          key={data?.content_meta?.id}
          boardAlias={boardAlias}
          articleId={articleId}
          categoryAlias={categoryAlias}
          query={query}
        />
      )}

      {option.relativeArtice && (
        <PrevnextArticle options={option} boardType={boardType} />
      )}

      {option.comment && (
        <BottomButtonWrap>
          <ContainedButton
            size="medium"
            type="outlineSecondary"
            onClick={handleLinkList}
            width="100px"
          >
            {t('list')}
          </ContainedButton>
        </BottomButtonWrap>
      )}
    </Container>
  )
}

export default BoardView
