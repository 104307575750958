import styled, { css } from 'styled-components'

//버튼 2개 두면 양쪽으로 정렬
//좌우로 정렬하면서 버튼 여러개 두고싶을땐 div로 감싸서 사용
export const BottomButtonWrap = styled.div`
  display: flex;
  padding: ${({ size }) => (size === 'narrow' ? 20 : 40)}px 0;
  justify-content: center;

  &:has(> div) {
    justify-content: space-between;
  }

  &:has(> button:nth-child(2)) {
    justify-content: space-between;
  }
`

// 게시판 상세 추가 정보
export const ExtraContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.glyphs200};
  & > span {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 9px;
    &:first-child:not(:last-child):after {
      content: '';
      position: absolute;
      right: -1px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: ${({ theme }) => theme.color.glyphs300};
    }
    & > button {
      display: flex;
      align-items: center;
      & svg {
        fill: ${({ theme }) => theme.color.point4};
      }
    }
    & span {
      margin: 0 4px;
    }
    & em {
      color: ${({ theme }) => theme.color.glyphs250};
    }
  }
  & > span:first-child {
    padding-left: 0;
  }
`

//게시판 뷰 에디터 영역
export const ViewEditorSection = css`
  word-break: break-word;

  a[href=NOT_ALLOWED_URL] {
    pointer-events: none;
    cursor: default;
    color: ${({ theme }) => theme.color.glyphs200};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.point1};
    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    list-style-position: initial;
    padding: 0 0 0 40px;
  }
  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  p {
    margin: 0;
  }

  table {
    td {
      border: 1px solid ${({ theme }) => theme.color.bright200};
    }
  }

  .fe-emoticon {
    max-width: 85px;
  }

  img {
    vertical-align: middle;
    max-width: 100%;
  }

  table {
    border: none;
    border-collapse: collapse;
    empty-cells: show;
    max-width: 100%;
    table-layout: fixed;

    &.sticky-top {
      overflow: auto;

      th {
        position: sticky!important;
        top: 0;
        z-index: 11;
        border-bottom-width: 2px;
        ${({ theme }) => theme.elevation[12]};
        background: ${({ theme }) => theme.color.bright200};
      }
    } 

    &.sticky-left td:nth-child(1){
      position: sticky!important;
      left: 0;
      z-index: 10;
      background: ${({ theme }) => theme.color.bright100};
      border-right-width: 2px;
      ${({ theme }) => theme.elevation[12]};
    } 

    td,
    th {
      color: ${({ theme }) => theme.color.glyphs200};
      border: 1px solid ${({ theme }) => theme.color.bright300};
      position: relative;

      .fe-image-inner {
        width: max-content;
      }
    }
  }
  strong {
    font-weight: 700;
  }

  blockquote {
    padding-left: 1em;
    border-left: 3px solid ${({ theme }) => theme.color.bright300};
    margin-left: 0;
    margin-right: 0;
  }

  em {
    font-style: italic;
  }

  .fr-toc {
    border: 1px solid ${({ theme }) => theme.color.bright300};
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 30px 30px;
    position: relative;
    display: inline-block;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 100%;
    min-width: 305px;
    font-size: 15px;

    ol {
      display: block;
      margin: 0;
      padding: 0;
      list-style-type: decimal;
      padding-inline-start: 15px;

      > li {
        margin: 30px 0 0 0;
        padding: 0;
        > ol {
          list-style-type: lower-roman;
          > li {
            margin: 10px 0 0 0;
            padding: 0;
          }

          > li > ol {
            list-style-type: lower-alpha;
            > li {
              margin: 5px 0 0 0;
              padding: 0;
            }
          }
        }
      }
    }
  }

  h1,
  h2,
  h3 {
    scroll-margin-top: 4rem;
    font-weight: 400;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .fr-image {
    line-height: 0;
    cursor: pointer;
  }

  [data-contents-type='title-box'] {
    padding: 4px 8px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div {
      margin: 0;
      padding: 0;
    }
  }

  [data-contents-type='flex-box'] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    &.start {
      justify-content: start;
    }

    &.end {
      justify-content: end;
    }

    &.center {
      justify-content: center;
    }

    &.between {
      justify-content: space-between;
    }

    &.around {
      justify-content: space-around;
    }

    &.evenly {
      justify-content: space-evenly;
    }

    &.wrap {
      flex-wrap: wrap;
    }

    &.nowrap {
      flex-wrap: nowrap;
    }

    div[data-contents-type='item'] {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
      box-sizing: content-box;
      word-break: break-all;
    }
  }

  hr[data-page-break]{
    border: 2px solid ${({ theme }) => theme.color.bright300};
  }
}
 
`
