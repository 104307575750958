/*
  [TYPE CODE]
  FAQ : 퍼플 FAQ (자주하는 질문)
  NOTICE: 퍼플 소식
  NEWS: 퍼블리싱 게임 소식 - 스토어
  COMMUNITY: 퍼블리싱 게임 커뮤니티 - 스토어
  LNEWS: 퍼블리싱 게임 소식 - 런처
  LCOMMUNITY: 퍼블리싱 게임 커뮤니티 - 런처
 */
export const BOARD_TYPE = {
  FAQ: 'FAQ',
  NOTICE: 'NOTICE',
  NEWS: 'NEWS',
  COMMUNITY: 'COMMUNITY',
  LNEWS: 'LNEWS',
  LCOMMUNITY: ' LCOMMUNITY'
}

export const BOARD_ERROR_KEY = {
  NOT_FOUND_BOARD_ALIAS: 'NOT_FOUND_BOARD_ALIAS',
  NOT_FOUND_CATEGORY: 'NOT_FOUND_CATEGORY',
  NOT_FOUND_ARTICLE: 'NOT_FOUND_ARTICLE',
  NOT_FOUND_COMMENT: 'NOT_FOUND_COMMENT',
  NOT_FOUND_SERVICE_ALIAS: 'NOT_FOUND_SERVICE_ALIAS',
  FAILED_AUTHENTICATION: 'FAILED_AUTHENTICATION',
  NOT_FOUND_AUTHENTICATION: 'NOT_FOUND_AUTHENTICATION',
  BANNED_ACCOUNT: 'BANNED_ACCOUNT',
  NO_PERMISSION: 'NO_PERMISSION',
  LIMIT_WRITE_ARTICLE_BY_RMS: 'LIMIT_WRITE_ARTICLE_BY_RMS',
  NOT_FOUND_REQUEST: 'NOT_FOUND_REQUEST',
  INVALID_REQUEST_BODY: 'INVALID_REQUEST_BODY',
  INVALID_REACTION: 'INVALID_REACTION',
  NOT_FOUND_SCRAP: 'NOT_FOUND_SCRAP',
  NOT_FOUND_COMMUNITY_PROVIDER: 'NOT_FOUND_COMMUNITY_PROVIDER',
  INVALID_PARAMETER: 'INVALID_PARAMETER',
  INVALID_COMMUNITY_AUTH: 'INVALID_COMMUNITY_AUTH',
  LIMIT_REPORT_COUNT: 'LIMIT_REPORT_COUNT',
  PURPLE_PROFILE_ERROR: 'PURPLE_PROFILE_ERROR',
  NOT_EXIST_PURPLE_PROFILE: 'NOT_EXIST_PURPLE_PROFILE',
  NOT_DEFINED_ERROR: 'NOT_DEFINED_ERROR'
}

/*
  아래 example의 module 값을 사용하여 에러 타입을 구분합니다.
  [Error response example]
  {
      "error": 49,
      "product": 1025,
      "module": 101014,
      "line": 75,
      "text": "NoPermission"
  }
*/

export const BOARD_API_ERROR_TYPE = {
  [BOARD_ERROR_KEY.NOT_FOUND_BOARD_ALIAS]: [102000], // 게시판 보드(board_alias) 정보를 찾을 수 없음
  [BOARD_ERROR_KEY.NOT_FOUND_CATEGORY]: [102001, 104001], // 카테고리 정보를 찾으 수 없음
  [BOARD_ERROR_KEY.NOT_FOUND_ARTICLE]: [103000, 103001], // 게시글을 찾을 수 없음
  [BOARD_ERROR_KEY.NOT_FOUND_COMMENT]: [104000, 104002, 104003], // 댓글을 찾을 수 없음
  [BOARD_ERROR_KEY.NOT_FOUND_SERVICE_ALIAS]: [107000], // 게시판 서비스(service_alias_ 정보를 찾을 수 없음
  [BOARD_ERROR_KEY.FAILED_AUTHENTICATION]: [
    101000, 101001, 101002, 101003, 101004, 101005, 101006, 101007, 101008,
    101009, 101010
  ], // 인증 실패
  [BOARD_ERROR_KEY.NOT_FOUND_AUTHENTICATION]: [1009], // 인증 정보를 찾을 수 없음
  [BOARD_ERROR_KEY.BANNED_ACCOUNT]: [101011], // 제재 계정
  [BOARD_ERROR_KEY.NO_PERMISSION]: [
    101012, 101013, 101014, 101015, 101016, 101017, 101018, 101019, 101020
  ], // 권한 없음
  [BOARD_ERROR_KEY.LIMIT_WRITE_ARTICLE_BY_RMS]: [101020], // RMS 게시글 작성 권한 제한
  [BOARD_ERROR_KEY.NOT_FOUND_REQUEST]: [100002, 0], // 요청된 데이터를 찾을 수 없음
  [BOARD_ERROR_KEY.INVALID_REQUEST_BODY]: [100003], // 유효하지 않은 RequestBody 데이터
  [BOARD_ERROR_KEY.INVALID_REACTION]: [105000], // 잘못된 reaction
  [BOARD_ERROR_KEY.NOT_FOUND_SCRAP]: [105002], // 스크랩 게시글 조회 실패
  [BOARD_ERROR_KEY.NOT_FOUND_COMMUNITY_PROVIDER]: [1000], // community-provider 서비스 정보를 찾을 수 없음
  [BOARD_ERROR_KEY.INVALID_PARAMETER]: [1003, 1001], // 잘못된 파라미터 정보
  [BOARD_ERROR_KEY.INVALID_COMMUNITY_AUTH]: [1011], // CommunityAuth 정보 검증 오류
  [BOARD_ERROR_KEY.LIMIT_REPORT_COUNT]: [105001], // 신고 횟수 제한
  [BOARD_ERROR_KEY.PURPLE_PROFILE_ERROR]: [2002], // 퍼플 프로필 조회 오류
  [BOARD_ERROR_KEY.NOT_EXIST_PURPLE_PROFILE]: [2003], // 퍼플 프로필 없음
  [BOARD_ERROR_KEY.NOT_DEFINED_ERROR]: [9999] // 알수없는 오류
}

//category l10n key
export const BOARD_CATEGORY_L10N = {
  NOTICE: 'Announcements',
  UPDATE: 'update',
  EVENT: 'event',
  FREE: 'general',
  TIP: 'Tips & Strategies',
  QNA: 'Q&A'
}
