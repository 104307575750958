import { useMemo, useCallback } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

// Utils
import storageUtility from 'utils/storageUtility'
import { dayjs } from '@purple/common/utils/dayjsUtility'

// constants
import { STORAGE_KEY } from 'constants/storageType'

// RTK
import { useGetMyGameRecordQuery } from 'store/services/query/gameQuery'

function useReviewRecommend(props) {
  const {
    gameCode,
    packageId,
    skip = false,
    recommendInfo = {},
    refetchOnMount = true
  } = props
  const { initConfig, storageKey } = recommendInfo

  const { npUserId } = useSelector(
    (state) => ({
      npUserId: state.userSlice.npUserId
    }),
    shallowEqual
  )

  // 나의 게임 이력 조회
  const {
    data: myGameRecordInfo,
    isError,
    isFetching
  } = useGetMyGameRecordQuery(
    { gameCode },
    {
      skip: !gameCode || !npUserId || skip,
      refetchOnMountOrArgChange: refetchOnMount
    }
  )

  // 리뷰 작성을 위한 unique id
  const packageBundleId = useMemo(
    () => packageId || myGameRecordInfo?.packages?.title_package,
    [packageId, myGameRecordInfo]
  )

  // 나의 리뷰(packageBundleId가 일치하는 첫번째 리뷰)
  const myReviewData = useMemo(
    () =>
      myGameRecordInfo?.reviews?.length > 0 &&
      myGameRecordInfo.reviews.find(
        (item) => item.package_bundle_id === packageBundleId
      ),
    [myGameRecordInfo, packageBundleId]
  )

  const getStorageInfos = useCallback(() => {
    const recommendStorage =
      (storageKey && storageUtility.get(storageKey)) || {}
    return {
      recommendStorage,
      recommendPackageInfo:
        recommendStorage && recommendStorage[npUserId]
          ? recommendStorage[npUserId]
          : {}
    }
  }, [npUserId, storageKey])

  const setStorageInfos = useCallback(() => {
    const { recommendStorage = {}, recommendPackageInfo = {} } =
      getStorageInfos()
    // 스토리지에 권장팝업 노출한 packageBundleId와 날짜 저장
    let copy = { ...recommendPackageInfo }
    //unix time milliseconds
    copy[packageBundleId] = dayjs().valueOf()
    const data = {
      ...recommendStorage,
      [npUserId]: copy
    }

    storageUtility.set(storageKey, JSON.stringify(data))
  }, [getStorageInfos, npUserId, storageKey, packageBundleId])

  // 리뷰 작성 권장 체크
  const recommendCheck = useMemo(() => {
    // 구매이력이 있는지
    const hasTitlePackage = myGameRecordInfo?.packages?.has_title_package
    if (storageKey && hasTitlePackage && !isFetching && npUserId) {
      const { recommendStorage = {}, recommendPackageInfo = {} } =
        getStorageInfos()

      localStorage.removeItem(STORAGE_KEY.STORE_REVIEW_RECOMMEND) // 구버전 키 삭제

      // 리뷰 작성 이력이 있는지
      if (myReviewData && myReviewData.is_deleted === false) {
        // 삭제되지 않은 리뷰 작성한 유저는 권장 정보 삭제
        let copy = { ...recommendPackageInfo }
        if (copy[packageBundleId]) {
          delete copy[packageBundleId]
          const data = {
            ...recommendStorage,
            [npUserId]: copy
          }
          storageUtility.set(storageKey, JSON.stringify(data))
        }
      } else {
        let playTimeDefault = initConfig?.playTime || 7200
        let afterUnit = initConfig?.unit || 'day'
        let afterCount = initConfig?.count || -7
        let playtimeCheck

        // 플레이타임 조건 체크
        if (myGameRecordInfo?.elapsed_time >= playTimeDefault) {
          playtimeCheck = true
        }

        // 노출 날짜 조건 체크
        const dateCheck = (() => {
          const popupDisplayDate = recommendPackageInfo[packageBundleId]
          const reviewDeletedDate = myReviewData?.deleted_date_time
            ? dayjs(myReviewData?.deleted_date_time)?.valueOf()
            : false
          let nextDisplayDate = dayjs().add(afterCount, afterUnit).valueOf()

          // 기준 날짜가 없는 경우
          if (popupDisplayDate === undefined && !reviewDeletedDate) {
            return true
          }

          // 기준날짜
          const compareDate =
            popupDisplayDate && reviewDeletedDate
              ? Math.max(popupDisplayDate, reviewDeletedDate)
              : reviewDeletedDate || popupDisplayDate

          // nextDisplayDate 값과 비교하여 노출 기준 리턴
          return compareDate < nextDisplayDate
        })()

        if (playtimeCheck && dateCheck) {
          return true
        } else {
          return false
        }
      }
    }
    return false
  }, [
    isFetching,
    npUserId,
    getStorageInfos,
    myGameRecordInfo,
    storageKey,
    packageBundleId,
    myReviewData,
    initConfig
  ])

  return {
    recommendCheck,
    setStorageInfos,
    myReviewData:
      myReviewData && !myReviewData.is_deleted ? myReviewData : null, // 삭제되지 않은 유효한 내 리뷰만 리턴
    myGameRecordInfo,
    packageBundleId
  }
}

export default useReviewRecommend
