import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import styled from 'styled-components'

// Style
import { ellipsis } from 'assets/styles/mixin'
import { media } from 'assets/styles/media'
import { Icons } from '@purple/design/react'

// Routes
import { moveBoard } from 'routes/board/locations'

// Utils

import { isAfter24Hours } from '@purple/common/utils/dayjsUtility'
import { getBoardType, getCategoryName } from 'utils/boardUtilsForStore'

// RTK
import { useGetPrevnextArticleQuery } from 'store/services/query/boardQuery'

const Container = styled.ul`
  margin-bottom: 24px;
`
const Item = styled.li`
  display: flex;
  height: 48px;
  cursor: pointer;
  padding: 0 8px;

  &:hover {
    border-radius: 4px;
    background-color: ${({ theme, isSearch }) =>
      isSearch ? 'initial' : theme.color.bright100};
  }

  ${media.phone`
    padding: 0;
    height: 32px;
  `};
`

const Title = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.glyphs200};

  > span {
    ${ellipsis}
  }

  svg:first-child {
    margin-right: 8px;
  }
  svg:last-child:not(:first-child) {
    margin-left: 3px;
  }

  i {
    margin-left: 4px;
    color: ${({ theme }) => theme.color.glyphs300};
  }

  ${media.phone`
    ${({ theme }) => theme.typography.body4};
  `};
`

const Category = styled.em`
  ${({ theme }) => theme.typography.subtitle2};
  color: ${({ theme }) => theme.color.point9};
  margin-right: 8px;
  flex-shrink: 0;

  &:empty {
    display: none;
  }

  ${media.phone`
    ${({ theme }) => theme.typography.button2};
  `};
`

function PrevnextArticle(props) {
  const {
    gameCode = (() => {
      const { gameCode } = useParams()
      return gameCode
    })(),
    boardAlias = (() => {
      const { boardAlias } = useParams()
      return boardAlias
    })(),
    articleId = (() => {
      const { articleId } = useParams()
      return articleId
    })(),
    categoryAlias = `${boardAlias}_${gameCode}`,
    boardType,
    options = {}
  } = props

  const option = {
    ...options
  }

  const { search } = useLocation()
  const history = useHistory()

  //  호출
  const { data, isError } = useGetPrevnextArticleQuery({
    boardAlias,
    articleId,
    params: {
      category: categoryAlias
    }
  })
  const { next_contents, prev_contents } = data || {}
  const dataList = [
    { type: 'next', ...next_contents?.[0] },
    { type: 'prev', ...prev_contents?.[0] }
  ]

  //상세 이동
  const handleClickView = useCallback(
    ({ boardAlias, articleId }) => {
      history.push({
        pathname: moveBoard(
          {
            gameCode,
            boardAlias,
            articleId
          },
          `${getBoardType({ boardAlias, boardType })}_VIEW`
        ),
        search
      })
    },
    [boardType, gameCode, search, history]
  )

  if (!dataList.filter((item) => item.id) || isError) return ''

  return (
    <Container>
      {dataList.map(
        (item, index) =>
          item.id && (
            <Item
              key={index}
              onClick={() =>
                handleClickView({
                  boardAlias: item?.root_board?.board?.board_alias,
                  articleId: item?.id
                })
              }
            >
              <Title>
                <Icons
                  name={item.type === 'next' ? 'ArrowUp' : 'ArrowDown'}
                  width="12"
                  height="12"
                />

                {option.category && (
                  <Category>
                    {getCategoryName(item?.category_board?.board_alias)}
                  </Category>
                )}
                <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                {option.comment && item.reactions?.comment_count ? (
                  <i>[{item.reactions?.comment_count || ''}]</i>
                ) : (
                  ''
                )}
                {option.newBadge &&
                  isAfter24Hours(item.timestamps?.posted_at) === false && (
                    <Icons name="BadgeNew" width="16" height="16" />
                  )}
              </Title>
            </Item>
          )
      )}
    </Container>
  )
}
export default PrevnextArticle
