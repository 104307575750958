//Custom event key
export const CUSTOM_EVENT_KEY = {
  POPUP_LAYER: 'popupLayer',
  //로비
  LOBBY_VOD_PLAY: 'lobbyVodPlay',
  DROPDOWN_MENU: 'dropdownMenu',
  LOBBY_GO_TO: 'lobbyGoTo',

  // 스토어
  SCROLL_EVENT: 'scrollEvent',
  BOARD_SEARCH: 'boardSearch',
  STORE_EVENT: 'storeEvent', // gnb 이벤트

  //비디오 플레이어 컨트롤용
  VIDEO_CONTROLLER: 'videoController',

  //토스트
  SHOW_TOAST: 'showToast'
}
