import { createSlice } from '@reduxjs/toolkit'
import { getTheme } from '@purple/common/utils/commonUtility'

// initial state
export const initialState = {
  storeTestValue: 'success',
  storeTheme: getTheme() || 'light',
  activeGameTab: null,
  launcherClosedPopupName: null
}

// reducer Slice
const storeSlice = createSlice({
  name: 'storeSlice',
  initialState,
  // 생성한 액션 (동기 처리 리듀서)
  reducers: {
    setTheme: (state, action) => {
      state.storeTheme = action.payload
    },
    setActiveGameTab: (state, action) => {
      state.activeGameTab = action.payload
    },
    setGtmInit: (state, action) => {
      state.gtmInit = action.payload
    },
    setLauncherClosedPopupName: (state, action) => {
      state.launcherClosedPopupName = action.payload
    }
  }
})

const { actions, reducer } = storeSlice

// Actions 내보내기
export const storeActions = actions
// Selector 내보내기
export const storeSelector = (state) => state.appSlice
// Reducer 내보내기
export default reducer
