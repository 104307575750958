import { createSlice, current } from '@reduxjs/toolkit'
import { npQuery } from 'store/services/query/npQuery'
import { purpleQuery } from 'store/services/query/purpleQuery'

// initial state
export const initialState = {
  loginStatus: window.NGP_VARS?.user?.id ? true : false,
  npUserId: window.NGP_VARS?.user?.id,
  npUserCountryCode: window.NGP_VARS?.user?.countryCode,
  purpleProfileInfo: {
    gameCode: 'plaync',
    serverId: null,
    characterId: null,
    characterName: null,
    profileImage: null,
    dateChangeCharacterName: null,
    dateChangeCharacterId: null
  }
}

// reducer Slice
const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  // 생성한 액션 (동기 처리 리듀서)
  reducers: {
    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload
    },
    setPurpleProfileInfo: (state, action) => {
      state.purpleProfileInfo = {
        ...state.purpleProfileInfo,
        ...action.payload
      }
    }
  },
  // 생선한 액션 외 외부 액션 참조 (비동기 처리 리듀서)
  extraReducers: (builder) => {
    builder
      .addMatcher(
        npQuery.endpoints.checkLoginUser.matchFulfilled,
        (state, action) => {
          const { user_id, country_code } = action.payload
          state.loginStatus = true
          state.npUserId = user_id ?? window.NGP_VARS?.user?.id
          state.npUserCountryCode =
            country_code ?? window.NGP_VARS?.user?.countryCode
        }
      )
      .addMatcher(
        purpleQuery.endpoints.getMyProfileInfo.matchFulfilled,
        (state, action) => {
          state.purpleProfileInfo = {
            ...state.purpleProfileInfo,
            ...action.payload
          }
          try {
            window.STORE?.setProfileInfo(action.payload) // GNB에 프로필 정보 전달
          } catch (e) {
            console.log(e)
          }
        }
      )
  }
})

const { actions, reducer } = userSlice

// Actions 내보내기
export const userActions = actions
// Selector 내보내기
export const userSelector = (state) => state.userSlice
// Reducer 내보내기
export default reducer
