import React, { Component } from 'react'
// import i18next from 'i18next'
// import { setPopupConfig } from '@purple/design/react'
import { PopupController } from '@purple/design/react'

import SystemContentPopup from 'components/Popup/components/SystemContentPopup'
import SystemPopup from 'components/Popup/components/SystemPopup'
import SystemCheckboxPopup from 'components/Popup/components/SystemCheckboxPopup'
import SendGiftPopup from 'components/Popup/components/SendGiftPopup'
import MediaViewer from 'components/Common/MediaViewer'
import AchievementPopup from 'components/Popup/components/AchievementPopup'
import RegisterReviewPopup from 'components/Popup/components/RegisterReviewPopup'
import AllReviewsPopup from 'components/Popup/components/AllReviewsPopup'
import GameBoardPopup from 'components/Popup/components/GameBoardPopup'
import SharePopup from 'components/Popup/components/SharePopup'
import BoardReportPopup from 'components/Popup/components/BoardReportPopup'
import AdConsentPopup from 'components/Popup/components/AdConsentPopup'

//component 등록
export const confirmPopup = (props, option) => {
  PopupController.open(<SystemPopup {...props} />, option)
}

export const confirmContentPopup = (props, option) => {
  PopupController.open(<SystemContentPopup {...props} />, option)
}

export const confirmCheckboxPopup = (props, option) => {
  PopupController.open(<SystemCheckboxPopup {...props} />, option)
}

/**
 * [Common]뷰어 팝업
 */
export const mediaViewerPopup = (props, option) => {
  PopupController.open(<MediaViewer {...props} />, {
    ...option,
    stringKey: 'mediaViewer'
  })
}

/**
 * 캐릭터 검색 및 선물 팝업
 */
export const sendGiftPopup = (props, option) => {
  PopupController.open(<SendGiftPopup {...props} />, option)
}

/**
 * 전체 업적 팝업
 */
export const achievementPopup = (props, option) => {
  PopupController.open(<AchievementPopup {...props} />, option)
}

/**
 * 리뷰 등록/수정 팝업
 */
export const registerReviewPopup = (props, option) => {
  PopupController.open(<RegisterReviewPopup {...props} />, option)
}

/**
 * 리뷰 전체보기 팝업
 */
export const allReviewsPopup = (props, option) => {
  PopupController.open(<AllReviewsPopup {...props} />, option)
}

/**
 * 게시판 팝업
 */
export const gameBoardPopup = (props, option) => {
  PopupController.open(<GameBoardPopup {...props} />, option)
}

/**
 * 게시글 공유하기 팝업
 */
export const sharePopup = (props, option) => {
  PopupController.open(<SharePopup {...props} />, option)
}

/**
 * 게시글 신고하기 팝업
 */
export const boardReportPopup = (props, option) => {
  PopupController.open(<BoardReportPopup {...props} />, option)
}

/**
 * 광고성 정보 수신동의 팝업
 */
export const adConsentPopup = (props, option) => {
  PopupController.open(<AdConsentPopup {...props} />, option)
}

//특정 레이어 닫을때사용
export const closePopup = (stringKey) => {
  PopupController.close(stringKey)
}

export const allClose = () => {
  PopupController.allClose()
}
