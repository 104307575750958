import { useCallback } from 'react'
import {
  useLazySetRecentGameQuery,
  useLazyGetMyProfileInfoQuery
} from 'store/services/query/purpleQuery'
import { useSelector, shallowEqual } from 'react-redux'
import { commonSessionError } from 'utils/appUtility'
import { openGnbProfileEditLayer } from 'utils/storeUtility'

// 최근본게임 저장
function useRecentGame() {
  const { loginStatus } = useSelector(
    (state) => ({
      loginStatus: state.userSlice.loginStatus
    }),
    shallowEqual
  )
  const [setRecentGameTrigger] = useLazySetRecentGameQuery()
  const setRecentGame = useCallback(
    async (gameCode) => {
      try {
        if (loginStatus) {
          await setRecentGameTrigger(gameCode).unwrap()
        }
      } catch (error) {
        console.log(error)
      }
    },
    [loginStatus, setRecentGameTrigger]
  )

  return { setRecentGame }
}

// 퍼플프로필 조회 및 콜백 실행
function useGetMyProfileInfo() {
  const { loginStatus } = useSelector(
    (state) => ({
      loginStatus: state.userSlice.loginStatus
    }),
    shallowEqual
  )
  const [getMyProfileInfoTrigger] = useLazyGetMyProfileInfoQuery()
  const getMyProfileInfo = useCallback(
    async (params = {}) => {
      const { successCallback = false, failCallback = false } = params
      try {
        if (loginStatus) {
          const profileInfo = await getMyProfileInfoTrigger().unwrap()
          if (profileInfo?.characterName) {
            successCallback && successCallback() // 퍼플프로필 있으면 콜백 실행
            return true
          } else {
            failCallback && failCallback()
            // 퍼플프로필 없으면 설정창 호출 및 실패 콜백
            openGnbProfileEditLayer({
              profileInfo,
              onSuccess: successCallback
            })
            return false
          }
        } else {
          failCallback && failCallback()
          commonSessionError()
        }
      } catch (error) {
        throw error
      }
    },
    [loginStatus, getMyProfileInfoTrigger]
  )

  return { getMyProfileInfo }
}

export { useRecentGame, useGetMyProfileInfo }
