import Cookies from 'js-cookie'
import { matchPath } from 'react-router-dom'
import { STORE_PATH } from 'routes/store/path'
import { APP_PATH } from 'routes/path'

// Utils
import {
  getLocaleKey,
  getCountryCode
} from '@purple/common/utils/locationUtility'
import { Config as config } from '@purple/common/utils/globalUtility'
import {
  isPurpleApp,
  isIOS,
  isWeb,
  isMobile
} from '@purple/common/utils/checkUtility'

// Constans
import { LANG_TYPE, COUNTRY_TYPE } from '@purple/common/constants/locationTypes'
import { ACCOUNT_LOGIN_TYPE } from '@purple/common/constants/accountTypes'

// 웹폰트 css append
// - 퍼플앱 내부 웹뷰이면서 /main 패스인 경우에만 웹폰트 사용X
export const appendFontCss = () => {
  const isMainPath = matchPath(window.location.pathname, {
    path: STORE_PATH.INDEX,
    exact: true,
    strict: true
  })

  if (!(isPurpleApp && isMainPath)) {
    const head = document.head
    const link = document.createElement('link')
    link.rel = 'preload stylesheet'
    link.as = 'style'
    link.href =
      'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&family=Noto+Sans:wght@400;500;700&family=Noto+Sans+JP:wght@400;500;700&family=Noto+Sans+Thai:wght@400;500;700&family=Noto+Sans+TC:wght@400;500;700&display=fallback'
    head.appendChild(link)
  }
}

// 스토어 페이지인지
export const isStorePage = () => {
  let paths = Object.values(STORE_PATH)
  if (isPurpleApp) {
    paths.push('/')
  }
  return paths.some((path) => {
    return (
      matchPath(window.location.pathname, {
        path,
        exact: true,
        strict: true
      }) !== null
    )
  })
}

// 콘티에 요청할 언어 4종 (기본값: 영어)
export const getContiLang = () => {
  const langList = [
    LANG_TYPE.KO_KR,
    LANG_TYPE.EN_US,
    LANG_TYPE.ZH_TW,
    LANG_TYPE.JA_JP
  ]
  const language = LANG_TYPE[getLocaleKey()]
  return langList.includes(language) ? language : LANG_TYPE.EN_US
}

// 게임 서비스 국가인지 확인
export const isServiceGameCountry = (countries) => {
  const country = getCountryCode()
  return countries.includes(country)
}

// 언어별 소식>퍼플 설명 링크
export const getPurpleIntroArticleId = () => {
  const purpleIntroArticleId = (config && config['purpleIntroArticleId']) || {}
  const language = LANG_TYPE[getLocaleKey()]

  return purpleIntroArticleId[language] || purpleIntroArticleId['en-US']
}

// 터치 기기 여부
export const isTouchdevice = !!(
  'ontouchstart' in window || // iOS & 안드로이드
  (navigator.pointerEanbled && navigator.maxTouchPoints > 0)
)

// 퍼플 런처 설치 체크 후 실행
export const runLauncher = ({ gameCode, npUserId }) => {
  // 윈도우 PC 웹 : 퍼플 런처 설치 체크
  const envName = config.envName
  const protocolEnv =
    envName?.indexOf('live') !== -1
      ? false // live일때는 전달 X
      : envName?.indexOf('stage') !== -1
      ? 'livestage'
      : 'rc'

  const getEncryptId = async (id) => {
    try {
      const msgUint8 = new TextEncoder().encode(id) // encode as (utf-8) Uint8Array
      const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8) // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array

      // convert base64 string
      let binary = ''
      let bytes = new Uint8Array(hashArray)
      let len = bytes.byteLength
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      const base64String = window.btoa(binary)

      // encoding
      return window.encodeURIComponent(base64String)
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  getEncryptId(npUserId).then((id) => {
    const protocol = `nc-purple://?${
      protocolEnv ? `serviceNetwork=${protocolEnv}&` : ''
    }appGroupCode=${gameCode}${id ? `&account=${id}` : ''}`

    window.protocolCheck(
      encodeURI(protocol),
      () => {
        console.log('미설치')
        window.STORE?.openDownloadLayer()
      },
      () => {
        console.log('설치')
      }
    )
  })
}

// 계정에 연결된 인증수단 목록에 따른 게임 예외처리 여부
export const isCredentialTypeEnabledGame = (gameCode) => {
  const country = window.NGP_VARS?.user?.countryCode
  const credentialCodes = window.NGP_VARS?.user?.userCredentialCodes || []
  const credentialGamesConfig = config.credentialGames || {}

  const filterCredential = () => {
    if (
      country === COUNTRY_TYPE.JP &&
      !credentialCodes.includes(ACCOUNT_LOGIN_TYPE.NCJ)
    ) {
      // JP 계정에 ncj 인증수단이 없는 경우: ncj 게임 제외
      return ACCOUNT_LOGIN_TYPE.NCJ
    } else {
      return ''
    }
  }
  const credentialGames =
    credentialGamesConfig[filterCredential()]
      ?.split(',')
      .map((gameCode) => gameCode.trim()) || []
  return !credentialGames.includes(gameCode)
}

// 현재 로그인한 인증수단에 따른 게임 예외처리 여부
// ex) nintendo 로 로그인한 계정: nlw 게임 예외처리
export const isLoginTypeEnabledGame = (gameCode) => {
  const credentialCode = window.NGP_VARS?.user?.userCredentialCode
  const exceptionGamesConfig = config.credentialExceptionGames || {}

  const credentialGames =
    exceptionGamesConfig[credentialCode]
      ?.split(',')
      .map((gameCode) => gameCode.trim()) || []
  return !credentialGames.includes(gameCode)
}

// 링크 형식이 포함된 텍스트를 html 로 변환
export const convertTextToHtmlLink = (text) => {
  if (!text) {
    return ''
  }

  // [텍스트|링크] => <a href="링크" target="_blank">텍스트</a>
  let replacedTextLink = text.replace(
    /\[(.+?)\|(.+?)\]/g,
    '<a href="$2" target="_blank">$1</a>'
  )
  // http(s)://~ => <a href="링크" target="_blank">링크</a>
  let replacedLink = replacedTextLink.replace(
    /(<a[^>]+?>.*?<\/a>|(?:https?:\/\/[^\s]+))/g,
    function (match, group1) {
      // a 태그는 변환하지 않음
      if (match.startsWith('<a')) {
        return match
      } else {
        return '<a href="' + match + '" target="_blank">' + match + '</a>'
      }
    }
  )

  return replacedLink
}

// Fisher-Yates 알고리즘을 이용한 랜덤한 array 반환
export const shuffleArray = (array) => {
  const shuffled = array.slice()
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
  }
  return shuffled
}

// 값이 있는 배열 항목을 , 로 붙이기
export const arrayToCommaString = (array) => {
  return array.filter(Boolean).join(', ')
}

// 노출 옵션별 목록
export const listByDisplay = (list) => {
  return (
    list?.filter((item) => {
      return item.display !== false
    }) || []
  )
}

// 판매 관련 정보 숨김 처리
export const isHidePrice = isPurpleApp && isIOS

// 게임/상품 상세에서 공유 URL 로 사용한 URL 추출
export const getShareLink = () => {
  const sharePath =
    matchPath(window.location.pathname, {
      path: APP_PATH.GOODS,
      exact: false,
      strict: true
    }) ||
    matchPath(window.location.pathname, {
      path: APP_PATH.GAME,
      exact: false,
      strict: true
    }) ||
    matchPath(window.location.pathname, {
      path: APP_PATH.BUNDLE,
      exact: false,
      strict: true
    })
  const url = new URL(
    sharePath.url,
    config?.purpleDomain || 'https://purple.plaync.com'
  ).href
  return url
}

// 카카오 sdk 삽입 및 init
export const kakaoInit = () => {
  try {
    if (!window.KaKao && isWeb && !isMobile) {
      const { key, url, integrity } = config?.kakaoSDK || {}
      if (!key || !url || !integrity) {
        return
      }
      const element = document.querySelector('body')
      const script = document.createElement('script')
      script.src = url
      script.async = true
      script.integrity = integrity
      script.crossOrigin = 'anonymous'
      script.onload = () => {
        if (key && window.Kakao && !window.Kakao.isInitialized()) {
          window.Kakao.init(key)
        }
      }
      element.appendChild(script)
    }
  } catch (e) {
    console.log('kakao sdk init 오류', e)
  }
}
