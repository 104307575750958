import { purpleApi } from 'store/services/purpleApi'

export const purpleQuery = purpleApi
  .enhanceEndpoints({
    addTagTypes: []
  })
  .injectEndpoints({
    endpoints: (build) => ({
      //최근 본 게임 저장
      setRecentGame: build.query({
        query: (params) => ({
          url: `/api/store/games/user/recent/${params}`,
          method: 'POST'
        })
      }),
      // 퍼플프로필 조회
      getMyProfileInfo: build.query({
        query: () => ({
          url: '/purple/getMyProfileInfo',
          method: 'POST'
        })
      })
    })
  })

export const {
  useSetRecentGameQuery,
  useLazySetRecentGameQuery,
  useGetMyProfileInfoQuery,
  useLazyGetMyProfileInfoQuery
} = purpleQuery
