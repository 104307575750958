import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  Icons,
  ContainedButton,
  Input,
  ActionButton
} from '@purple/design/react'

// Style
import { media } from 'assets/styles/media'

// Utils
import { copyText } from 'utils/popupUtility'
import { isLauncher } from '@purple/common/utils/checkUtility'

// hooks
import useMediaType from 'hooks/store/useMediaType'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.bg300};
  color: ${({ theme }) => theme.color.glyphs200};
  position: relative;
  width: 440px;
  transition: max-height 0.2s, background-color 0.3s;
  border-radius: 8px;
  ${({ theme }) => theme.elevation['24']};

  ${media.tablet`
    width: 320px;
  `}
`

const Header = styled.header`
  padding: 12px 12px 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${media.tablet`
    padding: 4px 4px 0 4px;
  `}
`

const Title = styled.h3`
  ${({ theme }) => theme.typography.headline6};
  color: ${({ theme }) => theme.color.glyphs200};
  padding-left: 12px;
  ${media.tablet`
    visibility: hidden;
  `}
`

const ContentWrap = styled.div`
  padding: 24px;
  ${media.tablet`
    padding: 16px;
  `}
`

const ShareIconWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 36px;

  &:has(> :nth-child(3)) {
    gap: 16px;
  }

  ${media.tablet`
    gap: 24px;

    &:has(> :nth-child(3)) {
    gap: 8px;
  }

  `}
`
const ShareItem = styled.div`
  max-width: 88px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  color: ${({ theme }) => theme.color.glyphs200};
  text-align: center;

  &:hover {
    background: ${({ theme }) => theme.color.bright100};
    color: ${({ theme }) => theme.color.glyphs100};
  }
  &:active {
    background: ${({ theme }) => theme.color.bright200};
    color: ${({ theme }) => theme.color.glyphs100};
  }

  & > svg {
    margin: 8px;
  }

  & > span {
    ${({ theme }) => theme.typography.body4};
    padding: 0 4px;
  }

  ${media.tablet`
    max-width: 80px;
    & > svg {
      width: 64px;
      height: 64px;
    }
    & > span {
      ${({ theme }) => theme.typography.body5};
    }
  `}
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.glyphs100};
  width: 60px;
  height: 60px;
  margin: 14px;

  & > svg {
    fill: ${({ theme }) => theme.color.glyphs900};
  }

  ${media.tablet`
    width: 53px;
    height: 53px;
    & > svg {
      width: 32px;
      height: 32px;
    }
  `}
`

const UrlWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  & > button {
    flex-shrink: 0;
    margin-left: 8px;
  }

  ${media.tablet`
    margin-top: 16px;
  `}
`

const SharePopup = (props) => {
  const { layerClose, arr = [], url = '' } = props

  const containerRef = useRef()
  const { t } = useTranslation()

  const { checkMediaType } = useMediaType('tablet')

  const onClickCopyUrl = useCallback(
    async (shareUrl = window.location.href) => {
      copyText(shareUrl)
    },
    []
  )

  const onClickKaKao = useCallback((url) => {
    if (!url) {
      console.error('URL Not Find ')
    } else {
      try {
        window.Kakao.isInitialized() &&
          window.Kakao.Share.sendScrap({
            requestUrl: url,
            installTalk: true
          })
      } catch (e) {
        console.log(e)
      }
    }
  }, [])

  const onClickFacebook = useCallback((url) => {
    if (!url) {
      console.error('URL Not Find ')
    } else {
      const loc = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
        url
      )}`
      window.open(
        loc,
        'share-facebook',
        isLauncher ? '' : 'width=600,height=400,toolbar=0,menubar=0'
      )
    }
  }, [])

  const onClickX = useCallback((url) => {
    if (!url) {
      console.error('(URL or msg) Not Find ')
    } else {
      const loc = `https://x.com/intent/post?url=${encodeURIComponent(url)}`
      window.open(
        loc,
        'share-twitter',
        isLauncher ? '' : 'width=600,height=400,toolbar=0,menubar=0'
      )
    }
  }, [])

  const iconSetData = useMemo(() => {
    let result = {}
    if (arr.length > 0) {
      arr.forEach((item) => {
        result[item] = true
      })
      return result
    }
    return {
      kakao: true,
      facebook: true,
      x: true,
      url: true
    }
  }, [arr])

  useEffect(() => {
    containerRef.current && containerRef.current.focus()
  }, [])

  return (
    <Wrapper tabIndex="0" ref={containerRef}>
      <Header>
        <Title>{t('sharing')}</Title>
        <ActionButton
          type="basic"
          size={checkMediaType ? 'medium' : 'large'}
          onClick={layerClose}
        >
          <Icons name="Close" width="24" height="24" />
        </ActionButton>
      </Header>

      <ContentWrap>
        <ShareIconWrap>
          {iconSetData.kakao && window.Kakao?.isInitialized() && (
            <ShareItem
              onClick={() => {
                onClickKaKao(url)
              }}
            >
              <Icons name="LogoKakao" width={72} height={72} />
              <span>{t('sns kakao')}</span>
            </ShareItem>
          )}
          {iconSetData.x && (
            <ShareItem
              onClick={() => {
                onClickX(url)
              }}
            >
              <IconWrap>
                <Icons name="LogoX" width={36} height={36} />
              </IconWrap>
              <span>{t('sns x')}</span>
            </ShareItem>
          )}
          {iconSetData.facebook && (
            <ShareItem
              onClick={() => {
                onClickFacebook(url)
              }}
            >
              <Icons name="LogoFacebook" width={72} height={72} />
              <span>{t('sns facebook')}</span>
            </ShareItem>
          )}
        </ShareIconWrap>
        {iconSetData.url && (
          <UrlWrap>
            <Input value={url} disabled={true} />
            <ContainedButton
              type="primary"
              size="medium"
              onClick={() => {
                onClickCopyUrl(url)
              }}
            >
              {t('copy')}
            </ContainedButton>
          </UrlWrap>
        )}
      </ContentWrap>
    </Wrapper>
  )
}

export default SharePopup
