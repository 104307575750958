import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { logd } from '@purple/common/logd'

// GTM
import GoogleAnalytics from 'config/analyticsConfig'

// components
import { Icons, ContainedButton } from '@purple/design/react'
import StarRating from 'components/Store/ViewComponent/StarRating'
import ReviewItem from 'components/Store/Game/Review/ReviewItem'
import { registerReviewPopup } from 'components/Popup/index'

// Style
import { media } from 'assets/styles/media'

// rtk
import {
  useGetGameProductsQuery,
  useGetGameReviewsQuery,
  useGetGameReviewsAverageQuery
} from 'store/services/query/gameQuery'
import { useGetMyProfileInfo } from 'hooks/common/usePurpleAsync'
import useReviewRecommend from 'hooks/common/useReviewRecommend'

// utils
import { moveGameView } from 'routes/store/locations'
import { commonError } from 'utils/purchaseUtility'
import { commonSessionError } from 'utils/appUtility'

// constants
import { GOODS_TYPE, GAME_MENU_TYPE } from 'constants/storeTypes'

const ReviewWrap = styled.div`
  & > button {
    display: none;
    ${media.tablet`
      display: inline-flex;
      width: 100%;
      margin-bottom: 10px;
    `};
  }
`
const AverageWrap = styled.div`
  display: flex;
  position: relative;
  border-radius: 8px;
  background-color: ${({ theme, type }) =>
    type === 'average' ? theme.color.bright200 : theme.color.bright100};
  padding: 32px;
  margin-bottom: 10px;

  ${media.tablet`
    padding: 16px;
    justify-content: center;
  `}

  &.is-popup {
    padding: 16px;
    ${media.tablet`
      margin-top: 20px;
    `}
  }

  & > button {
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);

    ${media.tablet`
      display: none;
    `};
  }
`
const Average = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  &:has(+ button) {
    margin: 0;
  }
  & > strong {
    ${({ theme }) => theme.typography.headline5};
    margin-left: 8px;

    ${media.tablet`
      ${({ theme }) => theme.typography.headline6};
    `};
  }
`

const List = styled.ul``

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isSummaryView }) =>
    isSummaryView ? 'flex-end' : 'center'};
  margin-top: 32px;

  ${media.tablet`
    justify-content: center;
    & > button {
      ${({ theme }) => theme.button.contained.outlineSecondary.size.small};
    }
  `};
`

const Blank = styled.div`
  padding: 40px 0;
  text-align: center;
  position: relative;

  & > p {
    ${({ theme }) => theme.typography.body3};
    color: ${({ theme }) => theme.color.glyphs300};
  }

  & > button {
    margin-top: 16px;
  }
`

const DetailTitle = styled.div`
  margin-top: ${({ goodsType }) =>
    goodsType === GOODS_TYPE.EDITION ? '100px' : '80px'};
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    ${({ theme }) => theme.typography.headline5};
    color: ${({ theme }) => theme.color.glyphs200};

    ${media.tablet`
      ${({ theme }) => theme.typography.subtitle5};
    `};
  }
  ${media.tablet`
    margin-top: 60px;
    margin-bottom: 24px;
  `};
`

function ReviewList(props) {
  const {
    gameInfo,
    goodsType,
    isSummaryView,
    goodsId,
    moveTab,
    type,
    hasPackage,
    layerKey
  } = props

  const [previousValue, setPreviousValue] = useState(null)
  const [isMarked, setIsMarked] = useState(true)
  const { t } = useTranslation()
  const history = useHistory()

  const { getMyProfileInfo } = useGetMyProfileInfo()

  // 상품 조회
  const { data: productData = {} } = useGetGameProductsQuery({
    gameCode: gameInfo.game_code,
    targets: 'storeDetail'
  })

  // [리뷰용] 패키지ID묶음(콤마로 구분)
  const packageBundleId = useMemo(() => {
    return goodsType === GOODS_TYPE.EDITION
      ? productData?.package_info?.title_package
      : productData?.package_info?.dlc &&
        productData?.package_info?.dlc[goodsId]
      ? productData?.package_info?.dlc[goodsId]?.package_keys?.join(',')
      : null
  }, [goodsType, productData, goodsId])

  // 평점조회
  const { data: reviewAverageInfo } = useGetGameReviewsAverageQuery(
    {
      gameCode: gameInfo.game_code,
      goodsType,
      packageBundleId
    },
    {
      skip: !packageBundleId
    }
  )

  // 내 게임 리뷰 및 권장 정보 조회
  const { myReviewData } = useReviewRecommend({
    gameCode: gameInfo.game_code,
    packageId: packageBundleId,
    refetchOnMount: false
  })

  // 리뷰 목록 조회
  const { data, isLoading } = useGetGameReviewsQuery(
    {
      gameCode: gameInfo.game_code,
      packageBundleId,
      goodsId,
      goodsType,
      size: 20,
      ...(isMarked === false && { isMarked }),
      ...(previousValue && { previousValue })
    },
    { skip: !packageBundleId }
  )

  // 내 리뷰 상단 정렬 및 요약보기 조건이 반영된 리뷰 목록
  const reviewList = useMemo(() => {
    return (
      data?.reviews?.reduce(
        (acc, item) => {
          if (
            item.id !== myReviewData?.id &&
            (!isSummaryView || (isSummaryView && acc.length < 3))
          ) {
            acc.push(item)
          }
          return acc
        },
        myReviewData
          ? [{ ...myReviewData, show_more: layerKey ? false : true }]
          : []
      ) || []
    )
  }, [data, myReviewData, isSummaryView])

  // 작성 버튼 클릭
  const handleClickRegisterReview = useCallback(async () => {
    try {
      GoogleAnalytics.sendGAEvent({
        event_category: '클릭',
        event_action: '게임 메인 리뷰2 - 버튼 클릭',
        event_label: `버튼 - ${myReviewData ? '수정하기' : '작성하기'} `,
        event_button_code: 'pbw_games_main_review2',
        event_game_code: gameInfo.game_code
      })
      logd({
        type: 'pbw_games_main',
        name: 'pbw_games_main_review2',
        value: gameInfo.game_code,
        data: { gameCode: gameInfo.game_code }
      })

      await getMyProfileInfo({
        successCallback: () =>
          registerReviewPopup({
            myReview: myReviewData,
            gameInfo,
            packageBundleId,
            goodsId,
            goodsType
          })
      })
    } catch (error) {
      // 세션 만료
      if (error?.status === 401) {
        commonSessionError()
      } else {
        commonError()
      }
    }
  }, [
    myReviewData,
    gameInfo,
    goodsType,
    packageBundleId,
    goodsId,
    getMyProfileInfo
  ])

  const handleClickAll = useCallback(() => {
    GoogleAnalytics.sendGAEvent({
      event_category: '클릭',
      event_action: '게임 메인 리뷰 더보기 - 버튼 클릭',
      event_label: '버튼 - 더보기',
      event_button_code: 'pbw_games_main_review2_all',
      event_game_code: gameInfo.game_code
    })
    logd({
      type: 'pbw_games_main',
      name: 'pbw_games_main_review2_all',
      value: gameInfo.game_code,
      data: { gameCode: gameInfo.game_code }
    })
    moveTab
      ? moveTab()
      : history.push({
          pathname: moveGameView({
            gameCode: gameInfo.game_code,
            gameMenu: GAME_MENU_TYPE.REVIEW
          })
        })
  }, [moveTab, gameInfo, history])

  const fetchNextPage = useCallback(() => {
    const lastId = data.reviews[data.reviews.length - 1].id
    setPreviousValue(lastId)
  }, [data])

  const isEmpty = useMemo(
    () => !data?.reviews || data.reviews.length === 0,
    [data]
  )

  useEffect(() => {
    // is_marked가 false 인 데이터가 있으면 false로 변경
    if (data?.reviews?.length > 0) {
      const isMarkedClear =
        data?.reviews?.findIndex((item) => item.is_marked === false) !== -1
      setIsMarked(!isMarkedClear)
    }
  }, [data])

  return (
    <>
      {isSummaryView && (
        <DetailTitle goodsType={goodsType}>
          <h5>{t('review')}</h5>
        </DetailTitle>
      )}

      {isLoading ? (
        ''
      ) : !isEmpty ? (
        <ReviewWrap>
          {/* 구매한 게임일 경우에만 노출 */}
          {hasPackage && !myReviewData && (
            <ContainedButton
              type="outlineSecondary"
              size="small"
              onClick={handleClickRegisterReview}
            >
              <Icons name="Pencil" width="24" height="24" />
              <span>{t('write')}</span>
            </ContainedButton>
          )}
          {reviewAverageInfo && (
            <AverageWrap
              type={'average'}
              className={cn({ 'is-popup': type === 'large-modal' })}
            >
              <Average>
                <StarRating
                  size={20}
                  initialValue={reviewAverageInfo.average}
                  readonly
                />
                <strong>{reviewAverageInfo.average.toFixed(1)}</strong>
              </Average>
              {/* 구매한 게임일 경우에만 노출 */}
              {hasPackage && !myReviewData && (
                <ContainedButton
                  type="outlineSecondary"
                  size="small"
                  onClick={handleClickRegisterReview}
                >
                  <Icons name="Pencil" width="24" height="24" />
                  <span>{t('write')}</span>
                </ContainedButton>
              )}
            </AverageWrap>
          )}
          <List>
            {reviewList.map((item) => {
              return (
                <ReviewItem
                  key={item.id}
                  item={item}
                  goodsType={goodsType}
                  packageBundleId={packageBundleId}
                  onClickModify={hasPackage ? handleClickRegisterReview : false} // 구매상태인 경우에만 리뷰 수정 가능
                />
              )
            })}
          </List>
          {(isSummaryView || data.has_more) && (
            <ButtonWrap isSummaryView={isSummaryView}>
              <ContainedButton
                type="outlineSecondary"
                size="medium"
                onClick={isSummaryView ? handleClickAll : fetchNextPage}
              >
                <Icons name="Add" width="20" height="20" />
                <span>{t('To see more details')}</span>
              </ContainedButton>
            </ButtonWrap>
          )}
        </ReviewWrap>
      ) : (
        <Blank>
          <p> {t('there are no reviews written')}</p>
          {/* 구매한 게임일 경우에만 작성버튼 노출 */}
          {hasPackage && !myReviewData && (
            <ContainedButton
              type="outlineSecondary"
              size="small"
              onClick={handleClickRegisterReview}
            >
              <Icons name="Pencil" width="24" height="24" />
              <span>{t('write')}</span>
            </ContainedButton>
          )}
        </Blank>
      )}
    </>
  )
}

export default ReviewList
