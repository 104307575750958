import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

// Style
import { media } from 'assets/styles/media'
import { ellipsis } from 'assets/styles/mixin'

// rtk
import { useDeleteGameReviewMutation } from 'store/services/query/gameQuery'

// components
import { Icons, Thumbnail, ActionButton } from '@purple/design/react'
import StarRating from 'components/Store/ViewComponent/StarRating'
import Popper, { PopperMoreContent } from 'components/Common/Popper'
import { confirmPopup } from 'components/Popup'

// utils
import { dateFormat } from '@purple/common/utils/dayjsUtility'
import { onError } from 'utils/imageUtility'
import { commonSessionError } from 'utils/appUtility'

const Item = styled.li`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.bright100};
  padding: 32px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${media.tablet`
    padding: 24px;
  `};
`
const ItemRight = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  & > div {
    margin-left: 8px;
  }
`

const Content = styled.p`
  margin-top: ${({ isHide }) => (isHide ? '12px' : '8px')};
  ${({ theme }) => theme.typography.body4};
  color: ${({ isHide, theme }) =>
    isHide ? theme.color.glyphs400 : theme.color.glyphs200};
  white-space: pre-line;
  word-break: break-word;
`

const Date = styled.span`
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.color.glyphs300};
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 8px;

  & > span {
    ${({ theme }) => theme.typography.body4};
    color: ${({ theme }) => theme.color.glyphs300};
    flex-shrink: 0;
    margin-left: 4px;
  }
`

const Profile = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  min-width: 0;

  & > strong {
    ${({ theme }) => theme.typography.subtitle3};
    color: ${({ theme }) => theme.color.glyphs200};
    margin-left: 8px;
    ${ellipsis}
  }
`

function ReviewItem({ item, onClickModify, goodsType, packageBundleId }) {
  const { t } = useTranslation()
  const [popper, setPopper] = useState({
    open: false,
    target: null,
    preventElements: []
  })

  const [deleteGameReview] = useDeleteGameReviewMutation()

  // 핸들러 - 더보기 버튼 클릭
  const handleMoreClick = useCallback(
    (e) => {
      const target = e.currentTarget
      if (!popper.open) {
        setPopper((prev) => ({
          ...prev,
          preventElements: [target],
          open: true,
          target
        }))
      } else {
        setPopper((prev) => ({
          open: false,
          target: null,
          preventElements: []
        }))
      }
    },
    [popper]
  )

  // 리뷰 삭제
  const handleDeleteClick = useCallback(async () => {
    try {
      await deleteGameReview({
        gameCode: item.game_code,
        reviewId: item.id,
        goodsType,
        packageBundleId
      }).unwrap()
    } catch (error) {
      if (error?.status === 401) {
        commonSessionError()
      }

      if (error?.data?.defined === 'NET_ERR_HAS_BEEN_CLOSED_TO_THE_PUBLIC') {
        confirmPopup({
          msg: `${t('This review has been made private due to violation')} ${t(
            'Please contact customer service'
          )}`,
          type: 'alert'
        })
      }
    }
  }, [deleteGameReview, goodsType, packageBundleId, item, t])

  return (
    <Item>
      <Title>
        <Profile>
          <Thumbnail
            type="character"
            size="100"
            imgBg="point7"
            alt=""
            src={item.profile?.image}
            onError={onError}
          />
          <strong>{item.profile?.name}</strong>
        </Profile>
        <ItemRight>
          <Date>{dateFormat(item.created_date_time)}</Date>
          {item.show_more && (
            <div onClick={handleMoreClick}>
              <ActionButton type="basicSub" size="medium">
                <Icons name="MoreVert" width="20" height="20" />
              </ActionButton>
              <Popper
                open={popper.open}
                target={popper.target}
                preventElements={popper.preventElements}
                portal={false}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 4]
                    }
                  },
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: document.getElementById('content'),
                      padding: 8
                    }
                  },
                  {
                    name: 'observeReferenceModifier',
                    enabled: true,
                    phase: 'main',
                    effect: ({ state, instance }) => {
                      const RO_PROP = '__popperjsRO__'
                      const { reference } = state.elements

                      reference[RO_PROP] = new ResizeObserver((entries) => {
                        instance.update()
                      })
                      reference[RO_PROP].observe(reference)

                      return () => {
                        reference[RO_PROP].disconnect()
                        delete reference[RO_PROP]
                      }
                    }
                  }
                ]}
                strategy={'absolute'}
                placement={'bottom-end'}
                onClose={() => {
                  setPopper((prev) => ({
                    open: false,
                    target: null,
                    preventElements: []
                  }))
                }}
              >
                {() => (
                  <PopperMoreContent>
                    {onClickModify && (
                      <button onClick={onClickModify}>{t('modify')}</button>
                    )}
                    <button
                      onClick={() => {
                        confirmPopup({
                          msg: t('are you sure you want to delete?'),
                          type: 'confirm',
                          onRight: handleDeleteClick
                        })
                      }}
                    >
                      {t('delete')}
                    </button>
                  </PopperMoreContent>
                )}
              </Popper>
            </div>
          )}
        </ItemRight>
      </Title>
      {item.is_show && (
        <StarRating size={20} initialValue={item.score} readonly />
      )}
      <Content isHide={!item.is_show}>
        {item.is_show
          ? item.content
          : t('This review has been made private due to violation')}
      </Content>
    </Item>
  )
}

export default ReviewItem
