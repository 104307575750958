import i18n from 'i18next'

// Utils
import {
  getLocaleKey,
  getCountryCode
} from '@purple/common/utils/locationUtility'
import { Config as config } from '@purple/common/utils/globalUtility'
import {
  isWeb,
  isMobile,
  isLauncher,
  isPurpleApp
} from '@purple/common/utils/checkUtility'
import { onWindowOpen } from '@purple/common/utils/commonUtility'
import { mobileScheme } from '@purple/common/utils/mobileUtility'
import { confirmPopup } from 'components/Popup'

// constants
import { PURPLE_APP_GROUP_CODE } from 'constants/storeTypes'

// 이메일 등록 팝업
export const openRegisterEmailPopup = () => {
  const url =
    config?.myEmailSetUrl || 'https://id.plaync.com/myinfo/email?layout=content'

  if (isPurpleApp) {
    mobileScheme.openWebView(url)
  } else {
    const options = 'width=480,height=680,toolbar=0,menubar=0'
    isLauncher
      ? onWindowOpen({
          url: url,
          name: 'registerEmail',
          option: options,
          viewTitle: i18n.t('purple store title main'),
          viewMinWidth: 480,
          viewMinHeight: 680
        })
      : window.open(url, '_blank', options)
  }
}

// 유료결제 보호자 동의 팝업
export const openParentAgreementPopup = () => {
  const language = getLocaleKey().split('_')[0].toLowerCase()
  const country = getCountryCode()
  const url = `${
    config.orderformUrl
  }/setting/parentAgreement?app=${PURPLE_APP_GROUP_CODE}&language=${language}&country=${country}&targetOrigin=${encodeURIComponent(
    window.location.origin
  )}&hideTopBar=${isWeb && isMobile ? false : true}`

  if (isPurpleApp) {
    mobileScheme.openWebView(url)
  } else {
    const options = 'width=480,height=760,toolbar=0,menubar=0'
    isLauncher
      ? onWindowOpen({
          url: url,
          name: 'parentAgreement',
          option: options,
          viewTitle: i18n.t('purple store title main'),
          viewMinWidth: 480,
          viewMinHeight: 765
        })
      : window.open(url, '_blank', options)
  }
}

// 본인인증 팝업
export const openIdentifyPopup = () => {
  const returnUrl = new URL('/order/result', window.location)
  returnUrl.searchParams.set('status', true)
  const cancleUrl = new URL('/order/result', window.location)
  cancleUrl.searchParams.set('status', false)
  const identifyUrl =
    config?.identifyUrl || 'https://id.plaync.com/identify/self/intro'
  const url = `${identifyUrl}?returnUrl=${returnUrl}&cancelUrl=${cancleUrl}`

  if (isPurpleApp) {
    mobileScheme.openExternalBrowser(url)
    // mobileScheme.openWebView(url)
  } else {
    const options = 'width=480,height=760,toolbar=0,menubar=0'
    isLauncher
      ? onWindowOpen({
          url: url,
          name: 'identify',
          option: options,
          viewTitle: i18n.t('purple store title main'),
          viewMinWidth: 480,
          viewMinHeight: 760
        })
      : window.open(url, '_blank', options)
  }
}

// 공통 에러
export const commonError = () => {
  confirmPopup({
    msg: i18n.t('an error occurred'),
    type: 'alert'
  })
}

// 원화 표시로 변환
export const formatKRW = (price) => {
  return i18n.t('krw', { num: Intl.NumberFormat('ko-KR').format(price) })
}

// 할인율 소수점 2자리까지 % 붙여서 표기
export const formatDiscount = (rate) => {
  return -(Math.round(rate * 100) / 100) + '%'
}

// 포인트 표기 포맷
export const formatPoint = (point) => {
  return point ? Intl.NumberFormat().format(Math.ceil(point)) : 0
}

// 이용등급 명칭 변환
export const usageLevelText = (level) => {
  switch (level) {
    case 'all':
      return i18n.t('usage level all')
    case '12':
      return i18n.t('usage level 12')
    case '15':
      return i18n.t('usage level 15')
    case '18':
      return i18n.t('Youth use not permitted')
    case '19':
      return i18n.t('Youth use not permitted')
    case 'test':
      return i18n.t('usage level test')
    case 'nonProfit':
      return i18n.t('usage level nonProfit')
    default:
      return null
  }
}
