import React, { useCallback, useEffect } from 'react'

// Component
import { sharePopup } from 'components/Popup'

// Utils
import { copyText } from 'utils/popupUtility'
import { isPurpleApp, isMobile } from '@purple/common/utils/checkUtility'
import { mobileScheme } from '@purple/common/utils/mobileUtility'

const useShare = () => {
  const copyUrlInfo = useCallback((shareUrl = window?.location?.href) => {
    copyText(shareUrl)
  }, [])

  const onShare = useCallback(
    async ({ url, title, option }) => {
      if (option?.arr?.length === 1 && option?.arr[0] === 'url') {
        copyUrlInfo(url)
      } else {
        if (isPurpleApp) {
          mobileScheme.shareMessage({
            content: url
          })
        } else if (isMobile) {
          try {
            await navigator.share({
              url,
              title
            })
          } catch (e) {
            console.log(e)
            // if (e.toString().includes('AbortError')) {
            // }
          }
        } else {
          sharePopup({ url, arr: option?.arr })
        }
      }
    },
    [copyUrlInfo]
  )

  return { onShare }
}

export default useShare
