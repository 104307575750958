import store from 'config/storeConfig'
import { MAINTENENCE_TYPE } from 'constants/appTypes'

import { Config as config } from '@purple/common/utils/globalUtility'
import { dayjs } from '@purple/common/utils/dayjsUtility'
import { isPurpleApp, isLauncher } from '@purple/common/utils/checkUtility'

//스토어에잇는 현재 시간 정보
export const currentTimeSotreInfo = () =>
  store.getState().appSlice.currentTimeInfo

//오늘인지
export const isToday = (date) => {
  const currentTimeInfo = currentTimeSotreInfo()
  const startDate = dayjs(date).format('YY.MM.DD.')
  const currentDate = dayjs()
    .add(currentTimeInfo.diff, 'millisecond')
    .format('YY.MM.DD.')

  return currentDate === startDate
}

// 점검 여부 일자로 확인
export const isMaintenance = (code) => {
  let { currentTime, diff } = store.getState().appSlice.currentTimeInfo

  currentTime = currentTime ?? dayjs()
  diff = diff ?? dayjs(currentTime).diff(dayjs())

  const startDate = config[`maintenance.${code}.startDate`]
  const endDate = config[`maintenance.${code}.endDate`]
  const realCurrentTime = dayjs().add(diff, 'millisecond')

  const isExistDate = !!startDate && endDate
  const result =
    isExistDate &&
    realCurrentTime > dayjs(startDate) &&
    realCurrentTime <= dayjs(endDate)

  return result
}

// 라임 상태 체크
export const hasLimeStatus = () => {
  return isMaintenance(MAINTENENCE_TYPE.LIME)
}

// 스토어 점검 상태 체크
export const checkStoreMaintenance = () => {
  return (isPurpleApp || isLauncher) && isMaintenance(MAINTENENCE_TYPE.STORE)
}

// gnb 퍼플프로필 설정
export const openGnbProfileEditLayer = ({
  profileInfo,
  target,
  onSuccess
} = {}) => {
  const { purpleProfileInfo } = store.getState().userSlice
  try {
    window.STORE?.openProfileEditLayer({
      profileInfo: profileInfo || purpleProfileInfo,
      target: target || document.getElementById('pageWrap'),
      onSuccess
    })
  } catch (e) {
    console.log(e)
  }
}
